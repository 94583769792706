// @font-face
//   font-family: 'comfortaa-regular'
//   src: url('../fonts/Comfortaa/Comfortaa-Regular.ttf')

// @font-face
//   font-family: 'special-elite'
//   src: url('../fonts/Special_Elite/SpecialElite-Regular.ttf')

@font-face {
  font-family: 'Roboto';
  weight: normal;
  src: url('../fonts/Roboto/Roboto-Light.ttf'); }

@font-face {
  font-family: 'Roboto';
  weight: bold;
  src: url('../fonts/Roboto/Roboto-Bold.ttf'); }

// $font-family-sans-serif:    'Helvetica Neue', Helvetica, Arial !default
// $font-family-serif:         'special-elite', serif !default
// $font-family-monospace:     'Roboto', 'Courier New', monospace !default
// $font-family-base:          $font-family-serif !default

// custom
// $font-family-logo:          $font-family-serif
